<template>
   <span
      :class="{ alarm: Alarm, fault: Fault, online: Online, offline: Offline, info: Info }"
      class="badge rounded-pill"
   >
      <slot></slot>
   </span>
</template>

<script>
export default {
   name: 'AmcBadge',
   props: {
      Alarm: {
         type: Boolean,
         default: false,
      },
      Fault: {
         type: Boolean,
         default: false,
      },
      Online: {
         type: Boolean,
         default: false,
      },
      Offline: {
         type: Boolean,
         default: false,
      },
      Info: {
         type: Boolean,
         default: false,
      },
   },
};
</script>

<style scoped lang="scss">
.badge {
   font-size: 10px;
}
.alarm {
   background-color: #ea3d2f;
}
.fault {
   background-color: #f5bd17;
}
.online {
   background-color: #2fa84f;
}
.offline {
   background-color: #929292;
}
.info {
   background-color: #0d6efd;
}
</style>
